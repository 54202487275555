export const API_ROUTES = {
  LANG: (lang: string) => `api/faq/new/?lang=${lang}`,
  UPLOAD_PHOTO: 'api/examples/upload/',
  UPLOAD_PAINTING_PHOTO: 'api/examples/upload/paint',
  UPLOAD_DOT_PAINTING_PHOTO: 'api/examples/upload/dotpaint',
  UPLOAD_EMBROIDERY_PHOTO: 'api/examples/upload/embroidery',
  CHECK_CODE: 'api/code/check/',
  CHECK_PAINTING_CODE: 'api/code/check/paint',
  CHECK_DOT_PAINTING_CODE: 'api/code/check/dotpaint',
  CHECK_EMBROIDERY_CODE: 'api/code/check/embroidery',
  GET_ONE_GOOD_IMG: 'api/examples/upload-code-three/',
  GET_ONE_GOOD_PAINTING_IMG: 'api/examples/upload-code-three/paint',
  GET_ONE_GOOD_DOT_PAINTING_IMG: 'api/examples/upload-code-three/dotpaint',
  GET_ONE_GOOD_EMBROIDERY_IMG: 'api/examples/upload-code-three/embroidery',
  PDF: 'api/pdf/mosaic/',
  PAINTING_PDF: 'api/pdf/paint/',
  DOT_PAINTING_PDF: 'api/pdf/dotpaint/',
  EMBROIDERY_PDF: 'api/pdf/embroidery/',
  SUBSCRIBE: 'api/products/subscribe/',
  MARKETPLACE_URLS: 'api/products/mosaic-marketplace/',
  LINKS: 'api/products/links/',
  TUTORIAL_VIDEO: 'api/products/video-tutorial/',
  PAINTING_TUTORIAL_VIDEO: 'api/products/video-tutorial/paint',
  DOT_PAINTING_TUTORIAL_VIDEO: 'api/products/video-tutorial/dotpaint',
  EMBROIDERY_TUTORIAL_VIDEO: 'api/products/video-tutorial/embroidery',
  INSTRUCTION_INFO: 'api/board/mosaic/info?order=',
  INSTRUCTION_GET_MATRIX: 'api/board/mosaic/?order=',
  INSTRUCTION_CHANGE_SQUARE: 'api/board/mosaic/',
  INSTRUCTION_CHANGE_SECTION: 'api/board/mosaic/section/',
  INFO_SIZES: 'api/info/sizes/',
  PRODUCT_OF_THE_WEEK: 'api/products/product-of-the-week/',
};
