import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ButtonBlock from '../../components/ButtonBlock';
import { APP_ROUTES } from '../../constants/appRoutes';
import { FLOW_TYPES } from '../../constants/flow';
import { PhotoContext } from '../../context/PhotoProvider';

import styles from './styles.module.scss';
import { Button } from '../../components';

const Painting = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setFlow } = useContext(PhotoContext);

  const navigateToChooseSize = () => {
    setFlow(FLOW_TYPES.DEMO);
    navigate(APP_ROUTES.CHOOSE_SIZE_PAINTING);
  };

  const navigateToInstruction = () => {
    setFlow(FLOW_TYPES.PURCHASED);
    navigate(APP_ROUTES.PAINTING_INSTRUCTION);
  };

  const navigateToVideoTutorial = () => {
    navigate(APP_ROUTES.VIDEO_TUTORIAL_PAINTING);
  };

  const navigateToPurchase = () => {
    navigate(APP_ROUTES.PURCHASE_PAINTING);
  };
  const navigateBack = () => {
    navigate(APP_ROUTES.CATALOGUE);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        <ButtonBlock
          text={t('videoTutorial')}
          description={t('productReview')}
          buttonText={t('choose')}
          buttonAction={navigateToVideoTutorial}
        />
        <ButtonBlock
          text={t('youAlreadyHaveMosaic')}
          description={t('getToWork')}
          buttonText={t('choose')}
          color="blue"
          buttonAction={navigateToInstruction}
        />
        <ButtonBlock
          text={t('uploadPhotoAndWeWillShowPainting')}
          buttonText={t('choose')}
          color="yellow"
          buttonAction={navigateToChooseSize}
        />

        <ButtonBlock
          text={t('youStillDontHaveMosaic')}
          buttonText={t('choose')}
          color="pink"
          buttonAction={navigateToPurchase}
        />
      </div>
      <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
    </div>
  );
};

export default Painting;
