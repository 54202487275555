import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../constants/api';
import styles from './styles.module.scss';

interface Props {
  article: string;
  imageUrl: string;
}

const Banner: FC<Props> = ({ article, imageUrl }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('weeklyProduct')}
        <br />
        <b>
          {t('partNumber')}
          <br />
          {article}
        </b>
      </p>
      <img src={`${API_URL}${imageUrl}`} alt="Баннер" />
    </div>
  );
};

export default Banner;
